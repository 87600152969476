import React, { useEffect, useState } from "react";
import axios from "axios";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import { Link } from "react-router-dom";
import Navbarr from "../navbar";
// import '../react-select/dist/react-select.css';

export default function AddContact() {
    const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;


    const Time = () => { }

    const [accordionOpen, setAccordionOpen] = useState(false);

    const CustomOption = ({ innerProps, label, data }) => (
        <div {...innerProps}>

            <span className="option-label">{label}<img src={data.image} alt={label} className="option-image" /></span>
            <span className="option-number"> ({data.number})</span>
        </div>
    );
    const options = [
        { value: 'apple', label: 'Apple', image: '../images/arab.png', number: 1 },
        { value: 'banana', label: 'Banana', image: '../images/arab.png', number: 2 },
        // Add more items as needed
    ];


    const [phoneNumber, setPhoneNumber] = useState('');
    const [valid, setValid] = useState(true);

    const handleChange = (value) => {
        setPhoneNumber(value);
        setValid(validatePhoneNumber(value));
    };

    const validatePhoneNumber = (phoneNumber) => {
        const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

        return phoneNumberPattern.test(phoneNumber);
    };

    const country = [
        {
            "name": "Afghanistan",
            "flag": "../images/bg.png",
            "code": "AF",
            "img": "../images/arab.png",
            "dial_code": "+937"
        },
        { "name": "Åland Islands", "flag": "🇦🇽", "code": "AX", "dial_code": "+358" },
        { "name": "Albania", "flag": "🇦🇱", "code": "AL", "dial_code": "+355" },
        { "name": "Algeria", "flag": "🇩🇿", "code": "DZ", "dial_code": "+213" },
        { "name": "American Samoa", "flag": "🇦🇸", "code": "AS", "dial_code": "+1684" },
        { "name": "Andorra", "flag": "🇦🇩", "code": "AD", "dial_code": "+376" },
        { "name": "Angola", "flag": "🇦🇴", "code": "AO", "dial_code": "+244" },
        { "name": "Anguilla", "flag": "🇦🇮", "code": "AI", "dial_code": "+1264" }
    ]

    // https://restcountries.com/v3.1/all
    // const [country, SetCountry] = useState([])
    const [selectedCountry, SetSelectedCountry] = useState('')
    // useEffect(() => {
    //     const fetch = async () => {
    //         try {
    //             const response = await axios.get('https://restcountries.com/v3.1/all')
    //             const countrydata = response.data
    //             SetCountry(countrydata)
    //         }
    //         catch (
    //         error
    //         ) {
    //             console.error('hhhhh', error);
    //         }
    //     }
    //     fetch()
    // }, [])
    const handleCountryChange = (e) => {
        SetSelectedCountry(e.target.value)
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbarr />
            <div className="flex xl:px-[calc((100%-1250px)/2)] px-3 py-5">
                <p className="text-[#000000] text-[12px] md:text-[16px] mr-1 roboto">
                    Home /
                </p>
                <p className="text-[#9E9E9E] text-[12px] md:text-[16px] roboto">
                    Become Vendor /
                </p>
                <p className="text-[#9E9E9E] text-[12px] md:text-[16px] roboto">
                    {" "}
                    Complete Profile
                </p>
            </div>
            <div className="grid lg:grid-cols-2 lg:px-[calc((100%-1250px)/2)]">
                <div className="lg:w-[100%] px-3 lg:px-0">
                    <p className="md:text-[48px] text-[34px] font-[700] text-[#444444] md:py-5 vol">
                        Add more <br />
                        <span className="text-[#008081] vol">Contact Details</span>
                    </p>
                    <div className="">
                        <input
                            type="text"
                            placeholder="Contact Person Name"
                            className=" mb-5 text-[#ADA4A5] roboto text-[12px] pl-5 w-full h-[48px] border-[#DDDDDD] border rounded-[10px]"
                        />
                        <div className="flex">
                            <PhoneInput
                                countryCodeEditable={false}
                                enableSearch
                                country='af'
                                type='text'
                                id='mobile'
                                className='w-full h-[48px] border rounded-[10px] !border-[#D6D6D6] mt-2 indent-2 pt-2'
                                required
                                placeholder='Enter your phone number'
                            />
                        </div>
                        <div className="py-3 grid justify-items-end">
                            <p className="text-[#008081] text-[12px] cursor-pointer" onClick={() => setAccordionOpen(!accordionOpen)}>+ Add Another Mobile Number</p>
                        </div>
                        <div className={`grid transition-all duration-300 ease-in-out text-slate-600 text-sm ${accordionOpen
                            ? "grid-rows-[1fr] opacity-100 h-[50px] mb-5"
                            : "grid-rows-[0fr] opacity-0 h-0 "
                            }`}>
                            <div className="overflow-ahidden">
                                <PhoneInput
                                    countryCodeEditable={false}
                                    enableSearch
                                    country='af'
                                    type='text'
                                    id='mobile'
                                    className='w-full h-[48px] border rounded-[10px] !border-[#D6D6D6] my-2 indent-2 pt-2'
                                    required
                                    placeholder='Enter your phone number'
                                />
                            </div>
                        </div>
                        <div className="flex">
                            <PhoneInput
                                countryCodeEditable={false}
                                enableSearch
                                country='af'
                                type='text'
                                id='mobile'
                                className='w-full h-[48px] border rounded-[10px] !border-[#D6D6D6] mt-2 indent-2 pt-2'
                                required
                                placeholder='Enter your phone number'
                            />
                        </div>
                        <div className="py-3 grid justify-items-end">
                            <p className="text-[#008081] text-[12px] cursor-pointer">+ Add Another Whatsapp Number</p>
                        </div>
                        <input
                            type="text"
                            placeholder="Landline Number"
                            className=" text-[#ADA4A5] roboto text-[12px] pl-5 w-full h-[48px] border-[#DDDDDD] border rounded-[10px]"
                        />
                        <div className="py-3 grid justify-items-end">
                            <p className="text-[#008081] text-[12px] cursor-pointer">+ Add Another Landline Number</p>
                        </div>
                        <input
                            type=""
                            placeholder="Email Address"
                            className="  text-[#ADA4A5] roboto text-[12px] pl-5 w-full h-[48px] border-[#DDDDDD] border rounded-[10px]"
                        />
                        <div className="py-3 grid justify-items-end">
                            <p className="text-[#008081] text-[12px] cursor-pointer">+ Add Another Email Number</p>
                        </div>
                        <Link to={'/complete-profile'}>
                            <button className="w-[173px] roboto h-[41px] border border-[#008081] text-[#008081] rounded-[4px]">
                                Previous
                            </button>
                        </Link>
                        <Link to={'/time'}>
                            <button className="w-[173px] roboto h-[41px] bg-[#008081] text-[#ffffff] rounded-[4px] ml-3">
                                next
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="mt-10 px-5 mb-10">
                    <img
                        src="../images/sideimg2.png"
                        alt="new-post"
                        className="text-[#999999]w-[400px] h-[400px]  md:w-[562px] md:h-[625px]"
                    />
                </div>
            </div>
        </>
    );
}
