import React, { useEffect, useState } from "react";
import Navbarr from "../navbar";
import { useLocation } from "react-router-dom";
import axios from "axios";

export default function ResetPassword() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      const { newPassword, confirmPassword } = formData;

      const response = await axios.post(
        `${baseUrl}/users/resetpasswordd?token=${token}`,
        {
          newPassword,
          confirmPassword,
        }
      );

      if (response.data.isSuccess) {
        localStorage.setItem("token", response.data.result.token);
        setSuccess(true);
      } else {
        setSuccess(response.data.message);
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status >= 400 &&
        err.response.status <= 500
      ) {
        setError(err.response.data.message);
      } else {
        setError("Internal Server Error");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      {/* <div className="bg-[#008081]">
                <Navbarr />
            </div>
            <div className="flex xl:px-[calc((100%-1250px)/2)] py-5 px-3">
                <p className="text-[#000000]">Home&nbsp;</p>/
                <p className="text-[#9E9E9E]">&nbsp;Forget Password</p>
            </div> */}
      <div className="grid lg:grid-cols-2 lg:px-[calc((100%-1250px)/2)]">
        <div className="lg:w-[100%] px-3 lg:px-0">
          <form onSubmit={handleSubmit}>
            <p className="md:text-[48px] text-[34px] font-[700] text-[#444444] md:py-5 vol">
              Reset <br />
              <span className="text-[#008081] vol">Password</span>
            </p>

            <input
              type="password"
              name="newPassword"
              placeholder="New Password"
              value={formData.newPassword}
              onChange={handleChange}
              className=" mb-5 text-[#787878]  text-[12px] pl-5 w-full h-[48px] border-[#DDDDDD] border rounded-[8px]"
            />
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleChange}
              className=" mb-5 text-[#787878]  text-[12px] pl-5 w-full h-[48px] border-[#DDDDDD] border rounded-[8px]"
            />
            <div className="flex justify-center">
              <button
                type="submit"
                className="w-[173px] roboto h-[41px] bg-[#008081] text-[#ffffff] rounded-[4px] ml-3"
              >
                Reset Password
              </button>
            </div>
          </form>
          {error && <p className="">{error}</p>}
          {success && (
            <p className="text-[#27AE60]-500">
              Password has been reset successfully
            </p>
          )}
        </div>
      </div>
      {/* <div className="mt-10 px-5 mb-10">
                    <img src="../images/login.png" alt="" className=' h-[300px]  md:w-[562px] md:h-[500px]' />
                </div> */}
      {/* </div> */}
    </>
  );
}
