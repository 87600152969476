import React, { useState, useEffect, useRef } from "react";

const Booking_History = () => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await fetch(`${baseUrl}/booking/get-booking-list`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("Fetched Data:", data);

        // Safely access bookings inside result
        const fetchedBookings = data.result?.bookings || [];
        setBookings(fetchedBookings);
        setLoading(false);
      } catch (error) {
        console.error("Fetch Error:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchBookings();
  }, [baseUrl, token]);

  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 7;

  const totalItems = bookings.length;
  const totalPages = Math.ceil(totalItems / perPage);

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;
  const currentData = bookings.slice(startIndex, endIndex);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [openedDropdownId, setOpenedDropdownId] = useState(-1);

  const toggleDropdown = (index) => {
    setOpenedDropdownId(openedDropdownId === index ? -1 : index);
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenedDropdownId(-1);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const headers = [
    "List Name",
    "Service Name",
    "Booked Date",
    // "Category",
    "Cost",
    "Contact No",
    "Payment Status",
  ];

  if (loading) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-10 text-red-500">Error: {error}</div>;
  }

  return (
    <>
      <div className="flex justify-between md:mt-6">
        <p className="md:text-[20px] text-[#008081] font-[500] mt-5 md:mt-0">
          Booking History
        </p>
      </div>

      <div className="max-w-full overflow-x-auto mb-10">
        <div className="max-w-full mt-5 rounded-sm border-l-stroke border-r-stroke bg-white shadow-default dark:border-t-strokedark border-b-[#CFD4D9] border-b-1 dark:bg-boxdark overflow-x-auto">
          <table className="w-full table-auto">
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th
                    key={index}
                    className="border-t-[2px] border-b-[2px] py-5 px-4 text-[10px] md:text-[14px] font-[500] text-[#444444]"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentData.length > 0 ? (
                currentData.map((item, index) => (
                  <tr key={index} className="text-[10px] md:text-[14px]">
                    {/* List Name  */}
                    <td className="border-[#787878]-600 border-b-[1px] text-center text-[#787878] py-5">
                      <p>{item.listName || "N/A"}</p>
                    </td>
                    {/* Service Name  */}
                    <td className="border-[#787878]-600 border-b-[1px] text-center text-[#787878] py-5">
                      <div className="flex flex-col items-center space-y-2">
                        {item.services.map((service, serviceIndex) => (
                          <p key={serviceIndex} className="">
                            {service.serviceName || "N/A"}
                          </p>
                        ))}
                      </div>
                    </td>

                    {/* Booked Date */}
                    <td className="border-[#787878]-600 border-b-[1px] text-center text-[#787878] py-5">
                      <p>{item.bookingDate || "N/A"}</p>
                    </td>

                    {/* Category */}
                    {/* <td className="border-[#787878]-600 border-b-[1px] text-center text-[#787878] py-5">
                      <p>{item.services[0]?.categoryName || "N/A"}</p>{" "}
                    </td> */}

                    {/* Cost */}
                    <td className="border-[#787878]-600 border-b-[1px] text-center text-[#787878] py-5">
                      <p>{item.subTotal || "N/A"}</p>
                    </td>

                    {/* Contact No */}
                    <td className="border-[#787878]  border-b-[1px] text-center text-[#787878] py-5">
                      <p>{item.customernumber || "N/A"}</p>{" "}
                    </td>

                    <td className="border-[#787878] border-b-[1px] text-center text-[#787878] py-5">
                      <p
                        className={`inline-flex rounded-[6px] py-1 px-3 lg:px-8 md:text-[12px] lg:text-[14px] font-medium 
                          ${
                            item.paymentStatus === "succeeded"
                              ? "bg-[#B6F8C7] text-[#008000]"
                              : item.paymentStatus === "Not Paid"
                              ? "bg-[#FFE5EE] text-[#AA0000]"
                              : item.paymentStatus === "Pending"
                              ? "bg-green-500 text-[#ffffff]"
                              : ""
                          }`}
                      >
                        {item.paymentStatus || "N/A"}
                      </p>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={headers.length} className="text-center py-5">
                    No bookings found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="flex justify-between w-[100px] float-right mt-4">
              <span className="text-gray-600">
                {currentPage} / {totalPages}
              </span>
              <button
                onClick={prevPage}
                disabled={currentPage === 1}
                className={`cursor-pointer ${
                  currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 18L9.07071 12.0707C9.03166 12.0317 9.03166 11.9683 9.07071 11.9293L15 6"
                    stroke="#B2B2B2"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <button
                onClick={nextPage}
                disabled={currentPage === totalPages}
                className={`cursor-pointer ${
                  currentPage === totalPages
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 18L14.9293 12.0707C14.9683 12.0317 14.9683 11.9683 14.9293 11.9293L9 6"
                    stroke="#B2B2B2"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Booking_History;
