import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbarr from "../navbar";

export default function Time() {
  const [selectAll, setSelectAll] = useState();
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedButtons([1, 2, 3, 4, 5, 6, 7]); // Select all days
    } else {
      setSelectedButtons([]); // Deselect all days
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [selectedButtons, setSelectedButtons] = useState([]);

  const handleButtonClick = (buttonId) => {
    // Check if the button is already selected
    if (selectedButtons.includes(buttonId)) {
      // If selected, remove it from the array
      setSelectedButtons(selectedButtons.filter((id) => id !== buttonId));
    } else {
      // If not selected, add it to the array
      setSelectedButtons([...selectedButtons, buttonId]);
    }
  };

  // time

  const [selectedOption, setSelectedOption] = useState();

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [selectedOptionclose, setSelectedOptionclose] = useState();

  const handleSelectChangeclose = (event) => {
    setSelectedOptionclose(event.target.value);
  };
  useEffect(() => {
    console.log(selectedOption);
    console.log(selectedOptionclose);
  }, [selectedOption, selectedOptionclose]);

  const hour = [
    { value: "00", label: "00" },
    { value: "01", label: "01" },
    { value: "02", label: "02" },
    { value: "03", label: "03" },
    { value: "04", label: "04" },
    { value: "05", label: "05" },
    { value: "06", label: "06" },
    { value: "07", label: "07" },
    { value: "08", label: "08" },
    { value: "09", label: "09" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
  ];

  // mins
  const [slectMins, setSelectMins] = useState();

  const handleSelectMins = (event) => {
    setSelectMins(event.target.value);
  };

  const [slectMinsclose, setSelectMinsclose] = useState();

  const handleSelectMinsclose = (event) => {
    setSelectMinsclose(event.target.value);
  };
  useEffect(() => {
    console.log(slectMins);
    console.log(slectMinsclose);
  }, []);

  const min = [
    { value: "00", label: "00" },
    { value: "01", label: "01" },
    { value: "02", label: "02" },
    { value: "03", label: "03" },
    { value: "04", label: "04" },
    { value: "05", label: "05" },
    { value: "06", label: "06" },
    { value: "07", label: "07" },
    { value: "08", label: "08" },
    { value: "09", label: "09" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
    { value: "21", label: "21" },
    { value: "22", label: "22" },
    { value: "23", label: "23" },
    { value: "24", label: "24" },
    { value: "25", label: "25" },
    { value: "26", label: "26" },
    { value: "27", label: "27" },
    { value: "28", label: "28" },
    { value: "29", label: "29" },
    { value: "30", label: "30" },
    { value: "31", label: "31" },
    { value: "32", label: "32" },
    { value: "33", label: "33" },
    { value: "34", label: "34" },
    { value: "35", label: "35" },
    { value: "36", label: "36" },
    { value: "37", label: "37" },
    { value: "38", label: "38" },
    { value: "39", label: "39" },
    { value: "40", label: "40" },
    { value: "41", label: "41" },
    { value: "42", label: "42" },
    { value: "43", label: "43" },
    { value: "44", label: "44" },
    { value: "45", label: "45" },
    { value: "46", label: "46" },
    { value: "47", label: "47" },
    { value: "48", label: "48" },
    { value: "49", label: "49" },
    { value: "50", label: "50" },
    { value: "51", label: "51" },
    { value: "52", label: "52" },
    { value: "53", label: "53" },
    { value: "54", label: "54" },
    { value: "55", label: "55" },
    { value: "56", label: "56" },
    { value: "57", label: "57" },
    { value: "58", label: "58" },
    { value: "59", label: "59" },
    { value: "60", label: "60" },
  ];

  // sesson

  const [selectedSesson, setSelectedSesson] = useState();

  const handleSelectSesson = (event) => {
    setSelectedSesson(event.target.value);
    console.log(event.target.value); // Log the selected value
  };
  const [selectedSessonclose, setSelectedSessonclose] = useState();

  const handleSelectSessonclose = (event) => {
    setSelectedSessonclose(event.target.value);
    console.log(event.target.value); // Log the selected value
  };

  return (
    <>
      <Navbarr />
      <div className="flex xl:px-[calc((100%-1250px)/2)] px-3 py-5 text-[12px] md:text-[18px]">
        <p className="text-[#000000] mr-1">Home /</p>
        <p className="text-[#9E9E9E]">Become Vendor / </p>
        <p className="text-[#9E9E9E]">Complete Profile </p>
      </div>
      <div className="md:flex px-3 xl:px-[calc((100%-1250px)/2)] mb-20">
        <div className="md:w-[50%]">
          <p className="md:text-[48px] text-[34px] font-[700] text-[#444444] md:py-5 vol">
            Add Business <br />
            <span className="text-[#008081] vol">timing</span>
          </p>
          <p className="text-[20px] font-[400]">Select days of the week</p>
          <div className="flex justify-between md:w-[300px] py-5">
            {/* Add more buttons as needed */}
          </div>
          <div className="flex w-full justify-between mb-5">
            <p className="w-[50%] font-[600] text-[12px] text-[#212121] pl-20">
              Open at
            </p>
            <p className="w-[50%] font-[600] text-[12px] text-[#212121] ml">
              Close at
            </p>
          </div>

          {/* Monday  */}
          <div className="flex">
            <button
              className="rounded-full h-[33px] w-[33px] border border-[#616161]"
              onClick={() => handleButtonClick(1)}
              style={{
                backgroundColor: selectedButtons.includes(1)
                  ? "#008081"
                  : "#FFFFFF",
                color: selectedButtons.includes(1) ? "#ffffff" : "#000000",
                border: selectedButtons.includes(1) ? "#000000" : "",
              }}
            >
              M
            </button>
            <div className="md:flex md:ml-10">
              <div className="flex  h-[35px] w-[224px] justify-between items-center">
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  {hour.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={slectMins}
                  onChange={handleSelectMins}
                >
                  {min.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  value={selectedSesson}
                  onChange={handleSelectSesson}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
              <div className="flex h-[35px] w-[224px] justify-between items-center md:ml-4">
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={selectedOptionclose}
                  onChange={handleSelectChangeclose}
                >
                  {hour.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={slectMinsclose}
                  onChange={handleSelectMinsclose}
                >
                  {min.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  value={selectedSessonclose}
                  onChange={handleSelectSessonclose}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
            </div>
          </div>
          {/* Tuesday  */}
          <div className="flex mt-5">
            <button
              className="rounded-full h-[33px] w-[33px] border border-[#616161]"
              onClick={() => handleButtonClick(2)}
              style={{
                backgroundColor: selectedButtons.includes(2)
                  ? "#008081"
                  : "#FFFFFF",
                color: selectedButtons.includes(2) ? "#ffffff" : "#000000",
                border: selectedButtons.includes(2) ? "#000000" : "",
              }}
            >
              T
            </button>
            <div className="md:flex md:ml-10">
              <div className="flex  h-[35px] w-[224px] justify-between items-center">
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  {hour.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={slectMins}
                  onChange={handleSelectMins}
                >
                  {min.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  value={selectedOption}
                  onChange={handleSelectSesson}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
              <div className="flex h-[35px] w-[224px] justify-between items-center md:ml-4">
                {/* close  */}
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={selectedOptionclose}
                  onChange={handleSelectChangeclose}
                >
                  {hour.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={slectMinsclose}
                  onChange={handleSelectMinsclose}
                >
                  {min.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <p className="">:</p>
                <select className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]">
                  <option value="">AM</option>
                  <option value="">PM</option>
                </select>
              </div>
            </div>
          </div>

          {/* wednesday  */}

          <div className="flex mt-5">
            <button
              className="rounded-full h-[33px] w-[33px] border border-[#616161]"
              onClick={() => handleButtonClick(3)}
              style={{
                backgroundColor: selectedButtons.includes(3)
                  ? "#008081"
                  : "#FFFFFF",
                color: selectedButtons.includes(3) ? "#ffffff" : "#000000",
                border: selectedButtons.includes(3) ? "#000000" : "",
              }}
            >
              W
            </button>
            {/* open  */}
            <div className="md:flex md:ml-10">
              <div className="flex  h-[35px] w-[224px] justify-between items-center">
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  {hour.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={slectMins}
                  onChange={handleSelectMins}
                >
                  {min.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  value={selectedOption}
                  onChange={handleSelectSesson}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
              {/* close  */}
              <div className="flex h-[35px] w-[224px] justify-between items-center md:ml-4">
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={selectedOptionclose}
                  onChange={handleSelectChangeclose}
                >
                  {hour.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={slectMinsclose}
                  onChange={handleSelectMinsclose}
                >
                  {min.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <p className="">:</p>
                <select className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]">
                  <option value="">AM</option>
                  <option value="">PM</option>
                </select>
              </div>
            </div>
          </div>

          {/* thursday  */}

          <div className="flex mt-5">
            <button
              className="rounded-full h-[33px] w-[33px] border border-[#616161]"
              onClick={() => handleButtonClick(4)}
              style={{
                backgroundColor: selectedButtons.includes(4)
                  ? "#008081"
                  : "#FFFFFF",
                color: selectedButtons.includes(4) ? "#ffffff" : "#000000",
                border: selectedButtons.includes(4) ? "#000000" : "",
              }}
            >
              T
            </button>
            {/* open  */}
            <div className="md:flex md:ml-10">
              <div className="flex  h-[35px] w-[224px] justify-between items-center">
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  {hour.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={slectMins}
                  onChange={handleSelectMins}
                >
                  {min.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  value={selectedOption}
                  onChange={handleSelectSesson}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
              {/* close  */}
              <div className="flex h-[35px] w-[224px] justify-between items-center md:ml-4">
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={selectedOptionclose}
                  onChange={handleSelectChangeclose}
                >
                  {hour.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={slectMinsclose}
                  onChange={handleSelectMinsclose}
                >
                  {min.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]">
                  <option value="">AM</option>
                  <option value="">PM</option>
                </select>
              </div>
            </div>
          </div>

          {/* friday  */}

          <div className="flex mt-5">
            <button
              className="rounded-full h-[33px] w-[33px] border border-[#616161]"
              onClick={() => handleButtonClick(5)}
              style={{
                backgroundColor: selectedButtons.includes(5)
                  ? "#008081"
                  : "#FFFFFF",
                color: selectedButtons.includes(5) ? "#ffffff" : "#000000",
                border: selectedButtons.includes(5) ? "#000000" : "",
              }}
            >
              F
            </button>
            {/* open  */}
            <div className="md:flex md:ml-10">
              <div className="flex  h-[35px] w-[224px] justify-between items-center">
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  {hour.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={slectMins}
                  onChange={handleSelectMins}
                >
                  {min.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  value={selectedOption}
                  onChange={handleSelectSesson}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
              {/* close  */}
              <div className="flex h-[35px] w-[224px] justify-between items-center md:ml-4">
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={selectedOptionclose}
                  onChange={handleSelectChangeclose}
                >
                  {hour.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={slectMinsclose}
                  onChange={handleSelectMinsclose}
                >
                  {min.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <p className="">:</p>
                <select className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]">
                  <option value="">AM</option>
                  <option value="">PM</option>
                </select>
              </div>
            </div>
          </div>

          {/* saturday  */}

          <div className="flex mt-5">
            <button
              className="rounded-full h-[33px] w-[33px] border border-[#616161]"
              onClick={() => handleButtonClick(6)}
              style={{
                backgroundColor: selectedButtons.includes(6)
                  ? "#008081"
                  : "#FFFFFF",
                color: selectedButtons.includes(6) ? "#ffffff" : "#000000",
                border: selectedButtons.includes(6) ? "#000000" : "",
              }}
            >
              S
            </button>
            {/* open  */}
            <div className="md:flex md:ml-10">
              <div className="flex  h-[35px] w-[224px] justify-between items-center">
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  {hour.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={slectMins}
                  onChange={handleSelectMins}
                >
                  {min.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  value={selectedOption}
                  onChange={handleSelectSesson}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
              {/* close  */}
              <div className="flex h-[35px] w-[224px] justify-between items-center md:ml-4">
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={selectedOptionclose}
                  onChange={handleSelectChangeclose}
                >
                  {hour.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={slectMinsclose}
                  onChange={handleSelectMinsclose}
                >
                  {min.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <p className="">:</p>
                <select className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]">
                  <option value="">AM</option>
                  <option value="">PM</option>
                </select>
              </div>
            </div>
          </div>

          {/* sunday  */}
          <div className="flex mt-5">
            <button
              className="rounded-full h-[33px] w-[33px] border border-[#616161]"
              onClick={() => handleButtonClick(7)}
              style={{
                backgroundColor: selectedButtons.includes(7)
                  ? "#008081"
                  : "#FFFFFF",
                color: selectedButtons.includes(7) ? "#ffffff" : "#000000",
                border: selectedButtons.includes(7) ? "#000000" : "",
              }}
            >
              S
            </button>
            {/* open  */}
            <div className="md:flex md:ml-10">
              <div className="flex  h-[35px] w-[224px] justify-between items-center">
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  {hour.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={slectMins}
                  onChange={handleSelectMins}
                >
                  {min.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  value={selectedOption}
                  onChange={handleSelectSesson}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
              {/* close  */}
              <div className="flex h-[35px] w-[224px] justify-between items-center md:ml-4">
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={selectedOptionclose}
                  onChange={handleSelectChangeclose}
                >
                  {hour.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p className="">:</p>
                <select
                  className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]"
                  id="myDropdowns"
                  value={slectMinsclose}
                  onChange={handleSelectMinsclose}
                >
                  {min.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>

                <p className="">:</p>
                <select className="w-[46px] h-[30px] rounded-[4px] border-2 text-[#BDC0CC] bg-[#F5F7FA] text-[12px]">
                  <option value="">AM</option>
                  <option value="">PM</option>
                </select>
              </div>
            </div>
          </div>
          <div className="flex h-6 my-5">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
            <p className="text-[#212121] text-[12px] ml-2 mt-[4px]">
              Select All Days
            </p>
          </div>

          <Link to={"/addcontact"}>
            <button className="w-[173px] roboto h-[41px] border border-[#008081] text-[#008081] rounded-[4px]">
              Previous
            </button>
          </Link>
          <Link to={"/business-category"}>
            <button className="w-[173px] roboto h-[41px] bg-[#008081] text-[#ffffff] rounded-[4px] ml-3">
              next
            </button>
          </Link>
        </div>
        <div className="md:w-[50%] grid justify-center">
          <img src="../images/time_men.png" alt="" className="mt-5 md:mt-0" />
        </div>
      </div>
    </>
  );
}
