import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default function HomeBanner() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [category, setCategory] = useState(null);

  useEffect(() => {
    fetch(`${baseUrl}/home/category`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCategory(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);

  const category1 = [
    {
      img: "https://www.shutterstock.com/shutterstock/photos/1974488912/display_1500/stock-photo-colored-supplies-for-cleaning-on-blue-background-spray-bottles-of-detergent-sponges-and-rags-1974488912.jpg",
    },
    {
      img: "https://info.ehl.edu/hubfs/Hospitality_Insights_EHL_Food_Service_Industry_Banner.jpg",
    },
    {
      img: "https://graphicsfamily.com/wp-content/uploads/edd/2023/02/Free-Download-Professional-Car-Promotion-Web-Banner-Design-1536x864.jpg",
    },
    {
      img: "https://img.freepik.com/premium-vector/laundry-service-horizontal-banner_554907-382.jpg",
    },
  ];

  return (
    <>
      <div className="flex justify-center">
        <div className="w-full xl:w-[80%] ">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {category1.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="carousel-item relative flex justify-center w-full">
                  <div className="w-[80%]">
                    <img
                      className="block h-[200px] lg:h-[400px] w-full rounded-xl cursor-pointer"
                      src={item.img}
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}
