import React, { useState, useEffect, useRef } from "react";
import My_Profile from "../My profile";
import Navbarr from "../navbar";
import Booking_History from "../booking history";
import MyLeads from "../my business pages/myleads";

const BusinessListingPage = () => {
    const itemsPerPage = 4;
    const [currentPage, setCurrentPage] = useState(1);
    const [currentStatus, setCurrentStatus] = useState("");

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    const [div1, setDiv1] = useState(true)
    const Div1 = () => {
        setDiv2(false);
        setDiv1(true)
        setDiv3(false)
        setDiv4(false)
    }
    const [div2, setDiv2] = useState(false)
    const Div2 = () => {
        setDiv2(true);
        setDiv1(false)
        setDiv3(false)
        setDiv4(false)
    }
    const [div3, setDiv3] = useState(false)
    const Div3 = () => {
        setDiv3(true);
        setDiv1(false)
        setDiv2(false)
        setDiv4(false)
    }
    const [div4, setDiv4] = useState(false)
    const Div4 = () => {
        setDiv4(true);
        setDiv1(false)
        setDiv2(false)
        setDiv3(false)

    }
    return (

        <>
            <div className="bg-[#008081]">
                <Navbarr />
            </div>
            <div className="flex xl:px-[calc((100%-1250px)/2)] px-3 py-5">
                <p className="text-[#000000] text-[12px] md:text-[16px] mr-1 roboto">
                    Home /
                </p>
                <p className="text-[#000000] text-[12px] md:text-[16px] mr-1 roboto">
                    My Business Listing /
                </p>
                <p className="text-[#9E9E9E] text-[12px] md:text-[16px] mr-1 roboto">
                    Jim Car Service Center
                </p>
            </div>

            <div className=" mt-10 xl:px-[calc((100%-1250px)/2)] px-3 w-full overflow">
                <p className="text-[#212121] text-[20px] font-[600] pb-3">Jim Car Service Center</p>
                <div className="flex">
                    <div className="w-[210px]">
                        <div className="flex h-[30px] items-center mt-6 cursor-pointer" onClick={Div1}>
                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.98274 15.9039C5.47052 15.9039 1.61719 16.5862 1.61719 19.3184C1.61719 22.0506 5.44608 22.7573 9.98274 22.7573C14.495 22.7573 18.3472 22.0739 18.3472 19.3428C18.3472 16.6117 14.5194 15.9039 9.98274 15.9039Z" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.98335 12.0069C12.9445 12.0069 15.3445 9.60579 15.3445 6.64468C15.3445 3.68357 12.9445 1.28357 9.98335 1.28357C7.02224 1.28357 4.62113 3.68357 4.62113 6.64468C4.61113 9.59579 6.99557 11.9969 9.94557 12.0069H9.98335Z" stroke="#757575" stroke-width="1.42857" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p className="text-[#008081] text-[16px] ml-2">My Leads</p>
                            <div className="ml-[65px]">
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_557_6660)">
                                        <path d="M4.88756 6L0.256281 10.5368C-0.0854273 10.8715 -0.0854273 11.4142 0.256281 11.7489C0.59799 12.0837 1.15201 12.0837 1.49372 11.7489L6.74372 6.60609C7.08543 6.27136 7.08543 5.72864 6.74372 5.39391L1.49372 0.251051C1.15201 -0.083684 0.59799 -0.083684 0.256281 0.251051C-0.0854273 0.585786 -0.0854273 1.1285 0.256281 1.46323L4.88756 6Z" fill="#757575" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_557_6660">
                                            <rect width="7" height="12" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                        <div className="flex h-[30px] items-center mt-6 cursor-pointer" onClick={Div2}>
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.60742 10.9715H24.402" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M19.1815 15.5279H19.1923" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14.0057 15.5279H14.0165" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.8182 15.5279H8.82901" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M19.1815 20.0621H19.1923" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14.0057 20.0621H14.0165" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.8182 20.0621H8.82901" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M18.7181 2.33325V6.1725" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.29235 2.33325V6.1725" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9446 4.17554H9.06612C5.63999 4.17554 3.5 6.08413 3.5 9.5924V20.1503C3.5 23.7138 5.63999 25.6665 9.06612 25.6665H18.9338C22.3708 25.6665 24.5 23.7469 24.5 20.2386V9.5924C24.5108 6.08413 22.3816 4.17554 18.9446 4.17554Z" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <p className="text-[#008081] text-[16px] ml-2">Add Photos</p>
                            <div className="ml-[45px]">
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_557_6660)">
                                        <path d="M4.88756 6L0.256281 10.5368C-0.0854273 10.8715 -0.0854273 11.4142 0.256281 11.7489C0.59799 12.0837 1.15201 12.0837 1.49372 11.7489L6.74372 6.60609C7.08543 6.27136 7.08543 5.72864 6.74372 5.39391L1.49372 0.251051C1.15201 -0.083684 0.59799 -0.083684 0.256281 0.251051C-0.0854273 0.585786 -0.0854273 1.1285 0.256281 1.46323L4.88756 6Z" fill="#757575" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_557_6660">
                                            <rect width="7" height="12" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                        <div className="flex h-[30px] items-center mt-6 cursor-pointer" onClick={Div3}>
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.34986 13.5314C2.09803 9.62304 3.56103 5.15588 7.66419 3.83404C9.82252 3.13754 12.2049 3.54821 13.9992 4.89804C15.6967 3.58554 18.1665 3.14221 20.3225 3.83404C24.4257 5.15588 25.898 9.62304 24.6474 13.5314C22.699 19.7264 13.9992 24.498 13.9992 24.498C13.9992 24.498 5.36353 19.7987 3.34986 13.5314Z" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M18.666 7.81665C19.9143 8.22032 20.7963 9.33448 20.9025 10.6423" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p className="text-[#008081] text-[16px] ml-2">Add Contact</p>
                            <div className="ml-[40px]">
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_557_6660)">
                                        <path d="M4.88756 6L0.256281 10.5368C-0.0854273 10.8715 -0.0854273 11.4142 0.256281 11.7489C0.59799 12.0837 1.15201 12.0837 1.49372 11.7489L6.74372 6.60609C7.08543 6.27136 7.08543 5.72864 6.74372 5.39391L1.49372 0.251051C1.15201 -0.083684 0.59799 -0.083684 0.256281 0.251051C-0.0854273 0.585786 -0.0854273 1.1285 0.256281 1.46323L4.88756 6Z" fill="#757575" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_557_6660">
                                            <rect width="7" height="12" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                        <div className="flex h-[30px] items-center mt-6 cursor-pointer" onClick={Div4}>
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.2737 8.89418L23.5475 7.63407C22.9332 6.56783 21.5717 6.2 20.504 6.8118V6.8118C19.9958 7.11119 19.3893 7.19614 18.8184 7.04789C18.2474 6.89965 17.7589 6.5304 17.4605 6.02156C17.2686 5.69813 17.1654 5.32975 17.1615 4.95367V4.95367C17.1789 4.35072 16.9514 3.76643 16.5309 3.33391C16.1105 2.90139 15.5329 2.65747 14.9296 2.65772H13.4666C12.8757 2.65771 12.3091 2.89319 11.8922 3.31206C11.4754 3.73093 11.2426 4.29865 11.2454 4.8896V4.8896C11.2279 6.10971 10.2338 7.08957 9.01355 7.08945C8.63747 7.08554 8.26909 6.98239 7.94567 6.79044V6.79044C6.87797 6.17864 5.51653 6.54647 4.90219 7.61271L4.12263 8.89418C3.50904 9.95909 3.87186 11.3197 4.93423 11.9377V11.9377C5.62478 12.3363 6.05018 13.0731 6.05018 13.8705C6.05018 14.6679 5.62478 15.4047 4.93423 15.8034V15.8034C3.87321 16.4172 3.50999 17.7745 4.12263 18.8362V18.8362L4.85947 20.107C5.14731 20.6264 5.63026 21.0096 6.20145 21.172C6.77264 21.3343 7.38497 21.2623 7.90295 20.972V20.972C8.41215 20.6749 9.01895 20.5935 9.58846 20.7459C10.158 20.8983 10.643 21.2718 10.9358 21.7836C11.1277 22.107 11.2309 22.4754 11.2348 22.8515V22.8515C11.2348 24.0841 12.234 25.0833 13.4666 25.0833H14.9296C16.1581 25.0834 17.1557 24.0906 17.1615 22.8621V22.8621C17.1587 22.2693 17.3929 21.7 17.8121 21.2808C18.2313 20.8616 18.8006 20.6274 19.3934 20.6303C19.7686 20.6403 20.1355 20.743 20.4613 20.9293V20.9293C21.5262 21.5429 22.8868 21.18 23.5048 20.1177V20.1177L24.2737 18.8362C24.5713 18.3254 24.653 17.717 24.5006 17.1457C24.3483 16.5745 23.9745 16.0875 23.4621 15.7927V15.7927C22.9496 15.4979 22.5758 15.011 22.4235 14.4397C22.2712 13.8685 22.3529 13.2601 22.6505 12.7492C22.844 12.4114 23.1242 12.1312 23.4621 11.9377V11.9377C24.5181 11.32 24.88 9.96737 24.2737 8.90485V8.90485V8.89418Z" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <ellipse cx="14.2044" cy="13.8704" rx="3.07552" ry="3.07551" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p className="text-[#008081] text-[16px] ml-2">Business Timing</p>
                            <div className="ml-[10px]">
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_557_6660)">
                                        <path d="M4.88756 6L0.256281 10.5368C-0.0854273 10.8715 -0.0854273 11.4142 0.256281 11.7489C0.59799 12.0837 1.15201 12.0837 1.49372 11.7489L6.74372 6.60609C7.08543 6.27136 7.08543 5.72864 6.74372 5.39391L1.49372 0.251051C1.15201 -0.083684 0.59799 -0.083684 0.256281 0.251051C-0.0854273 0.585786 -0.0854273 1.1285 0.256281 1.46323L4.88756 6Z" fill="#757575" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_557_6660">
                                            <rect width="7" height="12" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>

                    </div>
                    {div1 && (
                        <div className="w-full">
                            <MyLeads />
                        </div>
                    )}
                    {/* {div2 && (
                        <div className="w-full">
                            <Booking_History />
                        </div>
                    )} */}
                    {/* {div3 && (
                        <div className="w-full">
                            <Wishlist />
                        </div>
                    )}
                    {div4 && (
                        <div className="w-full pl-4">
                            <Setting />
                        </div>
                    )} */}

                </div>
            </div>
        </>
    )
}
export default BusinessListingPage;