import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbarr from '../navbar';

function Business_Category() {
    useEffect(()=>{
        window.scroll(0,0);
    },[])
    return (
        <div>
            <Navbarr />
            <div className="flex xl:px-[calc((100%-1250px)/2)] px-3 py-5">
                <p className="text-[#000000] text-[12px] md:text-[16px] mr-1 roboto">
                    Home /
                </p>
                <p className="text-[#9E9E9E] text-[12px] md:text-[16px] roboto">
                    Become Vendor /
                </p>
                <p className="text-[#9E9E9E] text-[12px] md:text-[16px] roboto">                    
                    Complete Profile
                </p>
            </div>
            <div className="md:flex px-3 xl:px-[calc((100%-1250px)/2)] mb-20">
                <div className="md:w-[58%]">
                    <p className="md:text-[48px] text-[34px] font-[700] text-[#444444] md:py-5 vol">
                        Add Business <br />
                        <span className="text-[#008081] vol">category</span>
                    </p>
                    <p className="text-[20px] font-[400] pb-5 pop">
                        Choose the right business categories so customer can easily find you
                    </p>
                    <div className="relative lg:w-[590px] h-[48px]  mb-10">
                        <input type="text" className='w-full h-[48px] pl-5 border border-[1px] border-[#DDDDDD] rounded-[10px] lg:w-[590px] relative' />
                        <img src="../images/search.png" alt="" className='absolute top-3 right-3' />
                    </div>
                    {/* <p className="text-[20px] font-[400] pb-5 pop py-4">Suggested Categories</p> */}
                    {/* <div className="md:w-[50%] roboto">
                        <div className="pt-3 text-[#212121]">
                            <div className="flex mt-3">
                                <div className="w-[50%] flex items-center">
                                    <label class="container mt-2"><p className="text-[12px] font-[500]">All</p>
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div className="w-[50%] flex items-center">
                                    <label class="container mt-2"><p className="text-[12px] font-[500]">Plumber</p>
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="flex mt-6">
                                <div className="w-[50%] flex items-center">
                                    <label class="container mt-2"><p className="text-[12px] font-[500]">Carpenters</p>
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div className="w-[50%] flex items-center">
                                    <label class="container mt-2"><p className="text-[12px] font-[500]">Painter</p>
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="flex mt-6">
                                <div className="w-[50%] flex items-center">
                                    <label class="container mt-2"><p className="text-[12px] font-[500]">Electrician </p>
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div className="w-[50%] flex items-center">
                                    <label class="container mt-2"><p className="text-[12px] font-[500]">Construction</p>
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>                     */}
                    <Link to={'/time'}>
                        <button className="w-[173px] roboto h-[41px] border border-[#008081] text-[#008081] rounded-[4px]">
                            Previous
                        </button>
                    </Link>
                    <Link to={'/add_cost'}>
                        <button className="w-[173px] roboto h-[41px] bg-[#008081] text-[#ffffff] rounded-[4px] ml-3">
                            next
                        </button>
                    </Link>
                </div>
                <div className="md:w-[40%] grid justify-items-center">
                    <img src="../images/business_category.png" alt="" className='pt-10'/>
                </div>
            </div>
        </div>
    )
}

export default Business_Category