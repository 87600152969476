import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Navbarr from "../navbar";

const All_Categories = () => {
  const baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEV
    : process.env.REACT_APP_BASE_URL_PROD;


  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [category, setCategory] = useState(null);

  useEffect(() => {
    fetch(`${baseUrl}/home/category`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setCategory(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);

  return (
    <>
      <Navbarr />
      {/* <div className="flex xl:px-[calc((100%-1250px)/2)] px-3 py-5">
        <p className="text-[#444444] text-[12px] md:text-[16px] mr-1 ">
          Home /
        </p>
        <p className="text-[#787878] text-[12px] md:text-[16px] ">
          All Categories
        </p>
      </div> */}
      
      <div className="mt-20  md:flex xl:px-[calc((100%-1250px)/2)] px-3 py-5">
        <div className="md:w-[50%] animate__animated animate__slideInDown">
          <p className="text-[#787878] lg:text-[64px] md:text-[44px] text-[30px] w-[280px] md:w-[380px] lg:w-full font-[700] vol">
            Get started your exciting{" "}
            <span className="text-[#008081] vol"> journey </span>
             with us
          </p>
        </div>
        <div className="md:w-[50%] flex justify-center mt-10 md:mt-0 animate__animated animate__slideInRight">
          <img src="../images/category.png" alt="" />
        </div>
      </div>
       <div className="mt-5 flex xl:px-[calc((100%-1250px)/2)] px-3 py-5">
        <Link className="flex gap-2 cursor-pointer" to="/">
          <span class="material-symbols-outlined">arrow_back</span>
          <p className="text-[#444444] text-[12px] md:text-[16px] mr-1">Back</p>
        </Link>
      </div>
      <div className="xl:px-[calc((100%-1250px)/2)]  px-3 py-5">
        <div className="grid justify-items-center w-full">
          <div className="grid justify-items-center w-full md:flex">
            <div className="md:flex md:flex-wrap lg:gap-14 lg:pl-3 gap-5 w-full">
              {/* {category &&
                category.map((item, index) => (
                  <Link to={`/category?id=${item.id}`}>
                    <div
                      key={index}
                      className="w-[200px] rounded-[12px] h-[172px] bg-[#F9F9F9] drop-shadow-2xl grid justify-items-center mt-5 md:mt-0"
                    >
                      <img
                        src={"./category/parcel.png"}
                        alt=""
                        className="mt-7"
                      />
                      <p className="text-[#3D3D3D]">{item.title}</p>
                      <p className="bg-[#BEE1E2] h-10 w-10 rounded-[50%] grid place-content-center">
                        {item.count}
                      </p>
                    </div>
                  </Link>
                ))} */}
            </div>
          </div>
        </div>
        <div>

          <div className="flex justify-items-center w-full md:flex justify-center">
            <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 md:gap-5 gap-2 grid-cols-2 scroll-containe r py-5  xl:gap-20 lg:gap-10 justify-center">
              {category &&
                category.map((item, index) => (
                  <Link to={`/category?id=${item.id}`}>
                    <div
                      key={index}
                      className="md:w-[200px] rounded-[8px] md:h-[200px] bg-[#F9F9F9] drop-shadow-xl grid justify-items-center mt-5 md:mt-0 transition-all hover:scale-105 hover:shadow-2xl"
                    >
                      <img
                        src={`/category/${item.image}`}
                        alt=""
                        className="md:h-[160px] md:w-[200px] rounded-t-xl"
                      />
                      <div className="bg-[#008081] h-[40px] grid place-content-center w-full">
                        <p className="text-[#ffffff] ">{item.title}</p>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
          {/* <div className="w-full grid justify-items-center mt-10">
            <Link to={"/categories"}>
              <button className="bg-[#008081] text-[16px] w-[210px] h-[46px] rounded-[5px] text-[#ffffff]">
                More Categories
              </button>
            </Link>
          </div> */}
        </div>
      </div>
      {/* <section>
        <div className="mb-10 mt-10 lg:px-[calc((100%-1250px)/2)]">
          <div className="bg-[#008081] h-[416px] rounded-[16px] relative">
            <div className="flex items-center justify-center pt-10">
              <div className=" p-4">
                <p className="text-center lg:text-[42px] text-[30px] text-[#f5f5f5] font-[400] vol">
                  Subscribe and get exclusive <br /> deals & offer
                </p>
              </div>
            </div>
            <div className="grid justify-items-center w-full pt-10">
              <div className="relative ">
                <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/parakeet-line/48/new-post.png"
                    alt="new-post"
                    className="text-[#f5f5f5]"
                  />
                </div>
                <input
                  type="email"
                  placeholder="Enter your email address"
                  className="p-2  pl-10 h-[52px] text-[#787878] z-20  rounded-[8px] md:w-[670px] w-[300px]"
                />
                <button
                  type="button"
                  className="absolute  bg-[#008081] text-white h-[34px] w-[130px] rounded-[8px] right-[8px] top-2 flex items-center justify-center"
                >
                  Subscribe
                </button>
              </div>
            </div>
            <img
              src="./images/circle.png"
              alt=""
              
            />
            <svg className="absolute bottom-0 h-[150px] w-[150px] lg:h-[250px] lg:w-[400px]" width="378" height="268" viewBox="0 0 378 268" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M95.6617 350.763C22.28 350.763 -37.1159 297.212 -37.1159 231.261C-37.1159 165.31 22.28 111.759 95.6617 111.759C169.043 111.759 228.439 165.31 228.439 231.261C228.439 297.212 169.043 350.763 95.6617 350.763Z" stroke="#E5E5E5" />
              <path d="M109.691 355.499C27.5927 355.499 -38.8694 295.586 -38.8694 221.786C-38.8694 147.987 27.5927 88.0738 109.691 88.0738C191.789 88.0738 258.252 147.987 258.252 221.786C258.252 295.586 191.789 355.499 109.691 355.499Z" stroke="#E5E5E5" />
              <path d="M121.09 360.237C31.2432 360.237 -41.5 294.668 -41.5 213.892C-41.5 133.117 31.2432 67.5481 121.09 67.5481C210.936 67.5482 283.679 133.117 283.679 213.892C283.679 294.668 210.936 360.237 121.09 360.237Z" stroke="#E5E5E5" />
              <path d="M137.75 363.394C41.1238 363.394 -37.1154 292.877 -37.1154 205.997C-37.1154 119.117 41.1238 48.6 137.75 48.6C234.376 48.6 312.615 119.117 312.615 205.997C312.615 292.877 234.376 363.394 137.75 363.394Z" stroke="#E5E5E5" />
              <path d="M153.971 366.552C50.8074 366.552 -32.7315 291.263 -32.7315 198.497C-32.7315 105.731 50.8074 30.4419 153.971 30.4419C257.134 30.4419 340.673 105.731 340.673 198.497C340.673 291.263 257.134 366.552 153.971 366.552Z" stroke="#E5E5E5" />
              <path d="M172.384 370.5C59.0513 370.5 -32.7315 287.788 -32.7315 185.866C-32.7315 83.943 59.0513 1.23131 172.384 1.23131C285.717 1.23132 377.5 83.943 377.5 185.866C377.5 287.788 285.717 370.5 172.384 370.5Z" stroke="#E5E5E5" />
            </svg>
          </div>
        </div>
      </section> */}
    </>
  );
};
export default All_Categories;
