import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Success from "../success";
import Failed from "../failed";
import Navbarr from "../navbar";

const ConfirmationPage = () => {
  const location = useLocation();
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [paymentStatus, setPaymentStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  
  // Ref to track if the booking has been inserted
  const hasInsertedRef = useRef(false);

  const queryParams = new URLSearchParams(location.search);
  const redirectStatus = queryParams.get("redirect_status");
  const paymentIntentId = queryParams.get("payment_intent");

  console.log("Redirect Status:", redirectStatus);
  console.log("Payment Intent ID:", paymentIntentId);

  const insertBookingToDatabase = async (isSuccess) => {
    // Use ref to prevent multiple insertions
    if (hasInsertedRef.current) return;

    try {
      const token = localStorage.getItem("token");
      const bookingData = JSON.parse(localStorage.getItem("bookingDetailsnexlum")) || {};

      const bookingToInsert = {
        bookingType: "Home Services",
        totalAmount: bookingData.totalAmount,
        currency: bookingData.currency,
        subTotal: bookingData.subTotal,
        paymentIntent: paymentIntentId,
        servicesInfo: [
          {
            FullName: bookingData.orderSummary?.FullName,
            customername: bookingData.orderSummary?.customername,
            customernumber: bookingData.orderSummary?.customernumber,
            date: bookingData.orderSummary?.date,
            email: bookingData.orderSummary?.email,
            listName: bookingData.orderSummary?.listName,
            serviceId: bookingData.orderSummary?.serviceId || null,
            shipaddress: bookingData.orderSummary?.shipaddress,
            slots: Array.isArray(bookingData.orderSummary?.slots),
            thumbnails: bookingData.orderSummary?.thumbnails,
            services: (bookingData.orderSummary?.services || []).map((service) => ({
              subId: service.subId,
              serviceName: service.serviceName,
              imageUrl: service.imageUrl,
              price: service.price,
              discountPrice: service.discountPrice,
              count: service.count,
            })),
          },
        ],
        paymentStatus: isSuccess ? "succeeded" : "failed",
      };

      const response = await fetch(`${baseUrl}/booking/insert-booking`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bookingToInsert),
      });

      if (!response.ok) {
        throw new Error("Failed to insert booking data.");
      }

      const data = await response.json();
      console.log("Booking inserted:", data);
      
      // Mark as inserted using ref
      hasInsertedRef.current = true;

      localStorage.removeItem("bookingDetailsnexlum");

      return data;
    } catch (error) {
      console.error("Error inserting booking:", error);
      setErrorMessage(error.message);
      throw error;
    }
  };

  useEffect(() => {
    // Check if redirectStatus is available and not yet inserted
    if (redirectStatus && !hasInsertedRef.current) {
      setPaymentStatus(redirectStatus);
      insertBookingToDatabase(redirectStatus).catch((error) => {
        console.error("Error in inserting booking:", error);
      });
    }
  }, [redirectStatus]); // Only depend on redirectStatus

  return (
    <>
      <div className="absolute w-full bg-white">
        <Navbarr />
      </div>
      <div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {paymentStatus === "succeeded" ? <Success /> : <Failed />}
      </div>
    </>
  );
};

export default ConfirmationPage;
