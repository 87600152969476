import React from "react";
import { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { jwtDecode } from "jwt-decode";
import Payment from "../payment";
import { useCurrencyStore } from "../../store/store";

import { Link, useNavigate } from "react-router-dom";
import OrderSummery from "../orderSummery";
import axios from "axios";

export default function Popup({ id, Close }) {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [serviceInfo, setServiceInfo] = useState({});
  const [serviceInfo1, setServiceInfo1] = useState([]);

  const [serviceDetails, setServiceDetails] = useState([]);
  const [currency, currencyRate] = useCurrencyStore((state) => [
    state.currency,
    state.currencyRate,
  ]);
  const navigate = useNavigate();

  // const token = localStorage.getItem("token");
  const [userInfo, setUserInfo] = useState({});
  console.log(userInfo, "pop");

  const [isEditing, setIsEditing] = useState(false);
  const [userName, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumer, setPhoneNumer] = useState("");
  const [message, setMessage] = useState(null);

  const [address, setAddress] = useState("");
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [errorTimeout, setErrorTimeout] = useState(null);

  const [cropper, setCropper] = useState(null);
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const cropperRef = useRef(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    fetch(`${baseUrl}/home/serviceById?id=${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setServiceInfo(data);
        setServiceDetails(data.serviceDetails);
        console.log("servicedetail data", data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, [id]);

  const [selectedServices, setSelectedServices] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState([]);

  const handleIncrement = (serviceId) => {
    setSelectedServices((prev) => ({
      ...prev,
      [serviceId]: {
        ...prev[serviceId],
        count: (prev[serviceId]?.count || 1) + 1,
      },
    }));
  };

  const handleDecrement = (serviceId) => {
    setSelectedServices((prev) => ({
      ...prev,
      [serviceId]: {
        ...prev[serviceId],
        count: prev[serviceId]?.count > 1 ? prev[serviceId]?.count - 1 : 1,
      },
    }));
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleCheckboxChange = (service) => {
    setSelectedServices((prevSelected) => {
      const updated = { ...prevSelected };
      const {
        subId: subId,
        count,
        price,
        discountprice,
        imageUrl,
        serviceName,
      } = service;

      if (updated[subId]) {
        delete updated[subId];
      } else {
        updated[subId] = {
          count: count || 1,
          price: price || 0,
          discountprice: discountprice || 0,
          imageUrl: imageUrl || "",
          serviceName: serviceName || "",
        };
      }
      return updated;
    });
  };

  const handleClick = (slotIndex) => {
    setSelectedSlots([slotIndex]);
  };

  const OpenBookingConform = () => {
    const bookingData = {
      services: Object.keys(selectedServices).map((subId) => {
        const service = selectedServices[subId];
        return {
          subId: parseInt(subId, 10),
          count: service.count,
        };
      }),
      serviceId: serviceInfo.serviceId,
      date: selectedDate,
      slots: selectedSlots,
      listName: serviceInfo.listName,
      customername: userName,
      customernumber: phoneNumer,
      shipaddress: address,
    };

    console.log(bookingData);

    addToCart(bookingData);
  };

  const addToCart = async (bookingData) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("No token found, redirecting to login");
      navigate("/log-In");
      return;
    }
    try {
      const response = await fetch(`${baseUrl}/booking/add-cart`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          bookings: [bookingData],
        }),
      });

      const result = await response.json();
      if (result.isSuccess) {
        console.log("Booking added to cart successfully", result);
        setConformBooking(true);
        await fetchServiceDetails();
      } else {
        console.error("Failed to add booking to cart:", result.message);
      }
    } catch (error) {
      console.error("Error adding booking to cart:", error);
    }
  };

  console.log(serviceInfo.serviceId, "dadad");

  console.log(serviceInfo1, "user");

  const fetchServiceDetails = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(
        `${baseUrl}/booking/checkout?serviceId=${serviceInfo.serviceId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setServiceInfo1(data.result);
      const preSelectedServices = {};
      data.result.services.forEach((service) => {
        preSelectedServices[service.subId] = {
          count: service.count,
        };
      });
      setSelectedServices(preSelectedServices);

      if (data.result.date) {
        setSelectedDate(new Date(data.result.date));
        setSelectedSlots(data.result.slots);
      }

      console.log("Service details:", data);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    fetchServiceDetails();
  }, [serviceInfo.serviceId]);

  const summaries = [1, 2, 3, 4];

  function Counter({ subId, count, onIncrement, onDecrement }) {
    return (
      <div className="flex items-center pr-5">
        <div className="h-[25px]">
          <button
            className="h-full w-[20px] bg-[#008081] text-[#ffffff] rounded-l-[2px]"
            onClick={() => onDecrement(subId)}
          >
            -
          </button>
        </div>
        <div className="h-[25px] w-[40px] bg-[#008081] bg-opacity-[20%] flex justify-center">
          {count}
        </div>
        <div>
          <button
            className="h-full w-[20px] bg-[#008081] text-[#ffffff] rounded-r-[2px]"
            onClick={() => onIncrement(subId)}
          >
            +
          </button>
        </div>
      </div>
    );
  }
  const getuserdetails = () => {
    const token = localStorage.getItem("token");
    console.log(token, "token");

    try {
      fetch(`${baseUrl}/userInfo/getUserDetails`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to fetch booking details");
          }
        })
        .then((data) => {
          console.log("sc", data.result);
          setUserInfo(data.result);
          setUsername(data.result.FullName);
          setEmail(data.result.email);
          setPhoneNumer(data.result.number);
          setAddress(data.result.userAddress);
        })
        .catch((error) => {
          console.error("Error fetching booking details:", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    getuserdetails();
  }, []);

  function ServiceSection({ service, isChecked, onCheckboxChange }) {
    // let service = props.service;
    const handleCheckboxChange = () => {
      onCheckboxChange(service);
    };

    return (
      <div>
        <div className="flex">
          <div className="w-[50px] pt-8 pl-4">
            <label className="container">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className="checkmark -mt-3"></span>
            </label>
          </div>
          <div className="flex items-center">
            <div>
              <img
                src={service.imageUrl}
                alt=""
                className="h-[50px] w-[50px] rounded-full"
              />
            </div>
            <div className="pl-2">
              <p className="text-[14px] text-[#444444]">
                {service.serviceName}
              </p>
              <p className="text-[14px] text-[#444444] flex gap-2">
                {" "}
                {/* {currency}  */}
                {/* {currency}  */}
                EUR
                <span> {(service.price * currencyRate).toFixed(2)}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Booking Conform

  const [conformbooking, setConformBooking] = useState(false);

  const closePopup = () => {
    Close();
    setConformBooking(false);
  };

  const [payment, setPayment] = useState(false);
  const PaymentOpen = () => {
    setPayment(true);
    document.body.classList.add("popup-open");
  };

  const PaymentClose = () => {
    setPayment(false);
    setConformBooking(false);
  };

  const ConformCancel = () => {
    setConformBooking(false);
  };

  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    // const token = sessionStorage.getItem('token');
    const token = localStorage.getItem("token");
    if (token != null) {
      const userInfo = jwtDecode(token);
      setLoggedIn(true);
    }
  }, []);

  return (
    <>
      <div className="mt-8">
        <div className="flex justify-between items-center h-[30px] my-3">
          <p className="md:text-[24px] text-[18px] text-[#222222] animate__animated animate__jello">
            {serviceInfo.listName}
          </p>
        </div>
        <div className="flex justify-center animate__animated animate__bounceIn">
          <img
            src={serviceInfo?.thumbnails}
            alt=""
            className="rounded-[8px] h-[200px] md:h-[300px] rep"
          />
        </div>
        {/* name  */}

        {/* gallery  */}
        <div className="mt-4 ">
          <p className="md:text-[20px] text-[14px] text-[#444444] ">Gallery</p>
          <div className="flex overflow-x-auto space-x-2 custom-scrollbar ">
            {serviceInfo.serviceImages?.map((item, index) => (
              <div
                key={index}
                className="flex-shrink-0 animate__animated animate__bounceIn "
              >
                <img
                  src={item}
                  alt={`Gallery Image ${index}`}
                  className="lg:w-[110px] h-[110px] rounded-[8px]"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Choose service  */}

      <div className="my-5">
        <p className="text-[#444444] text-[14px] md:text-[20px] mb-2">
          Choose Services
        </p>

        <div className="">
          {serviceDetails.map((service) => (
            <div
              key={service.subId}
              className="h-[80px] mt-5 flex justify-between items-center bg-[#ffffff] rounded-[8px] drop-shadow-md"
            >
              <ServiceSection
                key={service.subId}
                service={service}
                isChecked={!!selectedServices[service.subId]}
                onCheckboxChange={handleCheckboxChange}
              />
              <Counter
                subId={service.subId}
                count={selectedServices[service.subId]?.count || 1}
                onIncrement={handleIncrement}
                onDecrement={handleDecrement}
              />
            </div>
          ))}
        </div>
      </div>

      {/* book date */}
      {/* {serviceDetails.length > 0 && ( */}
      <div className="">
        <p className="text-[#444444] text-[14px] md:text-[20px] mb-2">
          Booking Date
        </p>
        <div className="relative">
          <input
            type="text"
            className="rounded-[8px] border-[#CFD3D4] outline-none pl-3 border-[1px] w-full h-[48px] text-[#666666] text-[16px]"
            placeholder="Choose Booking Date"
            value={selectedDate ? selectedDate.toLocaleDateString() : ""}
            readOnly
          />
          <div className="dropdown dropdown-left absolute top-4 right-2">
            <div tabIndex={0} role="button">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.75 15.5C1.3375 15.5 0.984375 15.3531 0.690625 15.0594C0.396875 14.7656 0.25 14.4125 0.25 14V3.5C0.25 3.0875 0.396875 2.73438 0.690625 2.44063C0.984375 2.14688 1.3375 2 1.75 2H2.5V0.5H4V2H10V0.5H11.5V2H12.25C12.6625 2 13.0156 2.14688 13.3094 2.44063C13.6031 2.73438 13.75 3.0875 13.75 3.5V14C13.75 14.4125 13.6031 14.7656 13.3094 15.0594C13.0156 15.3531 12.6625 15.5 12.25 15.5H1.75ZM1.75 14H12.25V6.5H1.75V14ZM1.75 5H12.25V3.5H1.75V5ZM7 9.5C6.7875 9.5 6.60938 9.42813 6.46563 9.28438C6.32188 9.14062 6.25 8.9625 6.25 8.75C6.25 8.5375 6.32188 8.35938 6.46563 8.21563C6.60938 8.07188 6.7875 8 7 8C7.2125 8 7.39063 8.07188 7.53438 8.21563C7.67813 8.35938 7.75 8.5375 7.75 8.75C7.75 8.9625 7.67813 9.14062 7.53438 9.28438C7.39063 9.42813 7.2125 9.5 7 9.5ZM4 9.5C3.7875 9.5 3.60938 9.42813 3.46562 9.28438C3.32187 9.14062 3.25 8.9625 3.25 8.75C3.25 8.5375 3.32187 8.35938 3.46562 8.21563C3.60938 8.07188 3.7875 8 4 8C4.2125 8 4.39062 8.07188 4.53438 8.21563C4.67812 8.35938 4.75 8.5375 4.75 8.75C4.75 8.9625 4.67812 9.14062 4.53438 9.28438C4.39062 9.42813 4.2125 9.5 4 9.5ZM10 9.5C9.7875 9.5 9.60938 9.42813 9.46563 9.28438C9.32188 9.14062 9.25 8.9625 9.25 8.75C9.25 8.5375 9.32188 8.35938 9.46563 8.21563C9.60938 8.07188 9.7875 8 10 8C10.2125 8 10.3906 8.07188 10.5344 8.21563C10.6781 8.35938 10.75 8.5375 10.75 8.75C10.75 8.9625 10.6781 9.14062 10.5344 9.28438C10.3906 9.42813 10.2125 9.5 10 9.5Z"
                  fill="#ADA4A5"
                />
              </svg>
            </div>
            <div
              tabIndex={0}
              className="dropdown-content z-[1] menu w-[250px] md:w-[360px] p-2 shadow bg-base-100 rounded-box"
            >
              <Calendar onChange={handleDateChange} value={selectedDate} />
            </div>
          </div>
        </div>
      </div>
      {/* )} */}

      {/* time slot */}
      {selectedDate && (
        <div className="mt-3">
          <p className="text-[#444444] text-[14px] md:text-[20px] mb-2">
            Available Time Slot
          </p>
          <div className="py-5 rounded-[8px]  gap-4">
            {serviceInfo.timeSlots
              ?.filter(
                (item) =>
                  new Date(selectedDate).toLocaleDateString("en-US", {
                    weekday: "long",
                  }) === item.day
              )
              .map((item, index) => (
                <div key={index} className="w-full">
                  <p className="text-[#444444] text-[12px] md:text-[16px] mb-1">
                    {item.day}
                  </p>

                  <div className="flex gap-2 w-full overflow-x-auto custom-scrollbar">
                    {item.slots?.length > 0 ? (
                      item.slots.map((slot, slotIndex) => (
                        <div
                          key={slotIndex}
                          style={{
                            backgroundColor: selectedSlots.includes(
                              `${slot.from}-${slot.to}`
                            )
                              ? "#e0e0e0"
                              : "#008081",
                            color: selectedSlots.includes(
                              `${slot.from}-${slot.to}`
                            )
                              ? "#000000" // Text color when selected
                              : "#ffffff",
                            cursor: "pointer",
                          }}
                          className="text-[12px]  md:text-[16px] border-2  border-[#008081] flex justify-center items-center rounded-full w-[88px] h-[28px] flex-shrink-0"
                          onClick={() => handleClick(`${slot.from}-${slot.to}`)}
                        >
                          <p className="text-[11px] ">{`${slot.from} - ${slot.to}`}</p>
                        </div>
                      ))
                    ) : (
                      <p className="text-center text-[#ff0000]">
                        No slots available
                      </p>
                    )}
                  </div>
                </div>
              ))}

            {serviceInfo.timeSlots?.filter(
              (item) =>
                new Date(selectedDate).toLocaleDateString("en-US", {
                  weekday: "long",
                }) === item.day
            ).length === 0 && (
              <p className="text-center text-[#ff0000] col-span-full">
                No slots available for the selected date
              </p>
            )}
          </div>
        </div>
      )}
      {selectedSlots.length > 0 && (
        <div>
          {isLoggedIn ? (
            <>
              <div className="border-2 border-gray-200 rounded-lg p-5">
                <div className="flex justify-between items-center mb-5">
                  <h2 className="text-xl font-semibold text-[#008081]">
                    Address Information
                  </h2>
                  <button
                    onClick={toggleEdit}
                    className="w-[90px] md:w-[140px] h-[30px] md:h-[48px] text-[10px] md:text-[14px] font-[400] text-[#008081] border-[#008081] border-[1px] rounded-[8px]"
                  >
                    {isEditing ? "Save" : "Edit Profile"}
                  </button>
                </div>

                {message && (
                  <div className="flex justify-center mb-5">
                    <p className="bg-green-100 w-[50%] text-center text-green-800 p-3 rounded-lg">
                      {message}
                    </p>
                  </div>
                )}

                <div className="md:flex md:gap-10">
                  <div className="md:w-[50%] mb-5">
                    <p className="text-[14px] text-[#008081] font-[400]">
                      Full Name
                    </p>
                    {isEditing ? (
                      <input
                        type="text"
                        className="w-full h-[48px] rounded-[8px] border-[1px] text-[#787878] pl-4 mt-3"
                        placeholder="Enter your Full Name"
                        value={userName}
                        name="userName"
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    ) : (
                      <p className="mt-3">{userName}</p>
                    )}
                  </div>

                  <div className="md:w-[50%] mb-5">
                    <p className="text-[14px] text-[#008081] font-[400]">
                      Phone Number
                    </p>
                    {isEditing ? (
                      <input
                        type="text"
                        className="w-full h-[48px] rounded-[8px] border-[1px] text-[#787878] pl-4 mt-3"
                        placeholder="Enter your Phone Number"
                        value={phoneNumer}
                        name="phoneNumer"
                        onChange={(e) => setPhoneNumer(e.target.value)}
                      />
                    ) : (
                      <p className="mt-3">{phoneNumer}</p>
                    )}
                  </div>
                </div>

                <div className="md:flex md:gap-10">
                  <div className="md:w-[50%] mb-5">
                    <p className="text-[14px] text-[#008081] font-[400]">
                      Address
                    </p>
                    {isEditing ? (
                      <input
                        type="text"
                        className="w-full h-[48px] rounded-[8px] border-[1px] text-[#787878] pl-4 mt-3"
                        placeholder="Enter your Address"
                        value={address}
                        name="address"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    ) : (
                      <p className="mt-3">{address}</p>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Link to="/log-In">
              <div className="text-center p-5 border-2 border-gray-200 rounded-lg">
                <p className="mt-3 text-[#008081]">
                  Click and login to view or edit your address details.
                </p>
              </div>
            </Link>
          )}
        </div>
      )}
      {/* Book Now  */}
      {Object.keys(selectedSlots).length > 0 ? (
        <div className="flex flex-col md:flex-row md:space-x-4 w-full justify-between items-center mt-7 mb-16 md:mb-2">
          {Object.keys(selectedSlots).length > 0 ? (
            <button
              onClick={OpenBookingConform}
              className="w-full md:w-auto h-[40px] rounded-[8px] bg-[#008081] text-[#ffffff] py-2 px-6"
            >
              View Summary
            </button>
          ) : (
            <button
              onClick={OpenBookingConform}
              className="w-full md:w-auto h-[40px] rounded-[8px] bg-[#008081] text-[#ffffff] py-2 px-6"
            >
              Continue to Summary
            </button>
          )}

          <Link to="/categories">
            <button className="w-full md:w-auto h-[40px] rounded-[8px] bg-[#f5f5f5] text-[#008081] py-2 px-6 mt-4 md:mt-0">
              Cancel
            </button>
          </Link>
        </div>
      ) : (
        <button
          onClick={OpenBookingConform}
          className="hidden w-full md:w-auto h-[40px] rounded-[8px] bg-[#008081] text-[#ffffff] py-2 px-6"
        >
          Continue to Summary
        </button>
      )}

      {conformbooking && (
        <OrderSummery
          id={id}
          closePopup={closePopup}
          PaymentOpen={PaymentOpen}
          ConformCancel={ConformCancel}
        />
      )}

      {payment && (
        <div className="fixed top-0 left-0 right-0 bottom-0 z-30">
          <div className="text-[#444444] overflow-y-scroll lg:w-[720px] md:w-[450px] bg-white rounded-t-[40px] md:rounded-[0px] pb-10 opacity-100 md:float-right h-full lg:p-6 p-4 m-4 md:m-0">
            <div className="cursor-pointer">
              <Link
                className="flex gap-2 cursor-pointer "
                onClick={PaymentClose}
              >
                <span class="material-symbols-outlined">arrow_back</span>
                <p>Back</p>
              </Link>
            </div>
            <div
              className="hidden float-right cursor-pointer"
              onClick={PaymentClose}
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.4 9.99844L0 8.59844L3.6 4.99844L0 1.42344L1.4 0.0234375L5 3.62344L8.575 0.0234375L9.975 1.42344L6.375 4.99844L9.975 8.59844L8.575 9.99844L5 6.39844L1.4 9.99844Z"
                  fill="#787878"
                />
              </svg>
            </div>
            <Payment />
          </div>
        </div>
      )}
    </>
  );
}

{
  /* rating  */
}
//   <div className="flex mt-1">
//   <div className="flex w-[100px] justify-between">
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 16 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M13.6663 5.72901L8.87301 5.32234L6.99967 0.902344L5.12634 5.32234L0.333008 5.72901L3.97301 8.88234L2.87967 13.569L6.99967 11.0823L11.1197 13.569L10.0263 8.88234L13.6663 5.72901ZM8.72634 9.18234L9.09967 10.789L7.68634 9.93568L6.99967 9.52234L6.31301 9.93568L4.89967 10.789L5.27301 9.18234L5.45301 8.39568L4.84634 7.86901L3.59301 6.78234L5.23967 6.64234L6.03967 6.57568L6.35301 5.83568L6.99967 4.32234L7.64634 5.84901L7.95967 6.58901L8.75968 6.65568L10.4063 6.79568L9.15301 7.88234L8.54634 8.40901L8.72634 9.18234Z"
//         fill="#FFC107"
//       />
//     </svg>
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 16 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M13.6663 5.72901L8.87301 5.32234L6.99967 0.902344L5.12634 5.32234L0.333008 5.72901L3.97301 8.88234L2.87967 13.569L6.99967 11.0823L11.1197 13.569L10.0263 8.88234L13.6663 5.72901ZM8.72634 9.18234L9.09967 10.789L7.68634 9.93568L6.99967 9.52234L6.31301 9.93568L4.89967 10.789L5.27301 9.18234L5.45301 8.39568L4.84634 7.86901L3.59301 6.78234L5.23967 6.64234L6.03967 6.57568L6.35301 5.83568L6.99967 4.32234L7.64634 5.84901L7.95967 6.58901L8.75968 6.65568L10.4063 6.79568L9.15301 7.88234L8.54634 8.40901L8.72634 9.18234Z"
//         fill="#FFC107"
//       />
//     </svg>
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 16 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M13.6663 5.72901L8.87301 5.32234L6.99967 0.902344L5.12634 5.32234L0.333008 5.72901L3.97301 8.88234L2.87967 13.569L6.99967 11.0823L11.1197 13.569L10.0263 8.88234L13.6663 5.72901ZM8.72634 9.18234L9.09967 10.789L7.68634 9.93568L6.99967 9.52234L6.31301 9.93568L4.89967 10.789L5.27301 9.18234L5.45301 8.39568L4.84634 7.86901L3.59301 6.78234L5.23967 6.64234L6.03967 6.57568L6.35301 5.83568L6.99967 4.32234L7.64634 5.84901L7.95967 6.58901L8.75968 6.65568L10.4063 6.79568L9.15301 7.88234L8.54634 8.40901L8.72634 9.18234Z"
//         fill="#FFC107"
//       />
//     </svg>
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 16 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M13.6663 5.72901L8.87301 5.32234L6.99967 0.902344L5.12634 5.32234L0.333008 5.72901L3.97301 8.88234L2.87967 13.569L6.99967 11.0823L11.1197 13.569L10.0263 8.88234L13.6663 5.72901ZM8.72634 9.18234L9.09967 10.789L7.68634 9.93568L6.99967 9.52234L6.31301 9.93568L4.89967 10.789L5.27301 9.18234L5.45301 8.39568L4.84634 7.86901L3.59301 6.78234L5.23967 6.64234L6.03967 6.57568L6.35301 5.83568L6.99967 4.32234L7.64634 5.84901L7.95967 6.58901L8.75968 6.65568L10.4063 6.79568L9.15301 7.88234L8.54634 8.40901L8.72634 9.18234Z"
//         fill="#FFC107"
//       />
//     </svg>
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 16 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M13.6663 5.72901L8.87301 5.32234L6.99967 0.902344L5.12634 5.32234L0.333008 5.72901L3.97301 8.88234L2.87967 13.569L6.99967 11.0823L11.1197 13.569L10.0263 8.88234L13.6663 5.72901ZM8.72634 9.18234L9.09967 10.789L7.68634 9.93568L6.99967 9.52234L6.31301 9.93568L4.89967 10.789L5.27301 9.18234L5.45301 8.39568L4.84634 7.86901L3.59301 6.78234L5.23967 6.64234L6.03967 6.57568L6.35301 5.83568L6.99967 4.32234L7.64634 5.84901L7.95967 6.58901L8.75968 6.65568L10.4063 6.79568L9.15301 7.88234L8.54634 8.40901L8.72634 9.18234Z"
//         fill="#FFC107"
//       />
//     </svg>
//   </div>
//   <p className="text-[#444444] text-[14px] ml-2">(1279 Rating) </p>
// </div>

// const handleClick = (slotIndex) => {
//   setSelectedSlots((prev) => {
//     const updatedSlots = [...prev];
//     if (updatedSlots.includes(slotIndex)) {
//       return updatedSlots.filter(index => index !== slotIndex);
//     } else {
//       return [...updatedSlots, slotIndex];
//     }
//   });
// };

{
  /* rating  */
}
//   <div className="flex mt-1">
//   <div className="flex w-[100px] justify-between">
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 16 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M13.6663 5.72901L8.87301 5.32234L6.99967 0.902344L5.12634 5.32234L0.333008 5.72901L3.97301 8.88234L2.87967 13.569L6.99967 11.0823L11.1197 13.569L10.0263 8.88234L13.6663 5.72901ZM8.72634 9.18234L9.09967 10.789L7.68634 9.93568L6.99967 9.52234L6.31301 9.93568L4.89967 10.789L5.27301 9.18234L5.45301 8.39568L4.84634 7.86901L3.59301 6.78234L5.23967 6.64234L6.03967 6.57568L6.35301 5.83568L6.99967 4.32234L7.64634 5.84901L7.95967 6.58901L8.75968 6.65568L10.4063 6.79568L9.15301 7.88234L8.54634 8.40901L8.72634 9.18234Z"
//         fill="#FFC107"
//       />
//     </svg>
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 16 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M13.6663 5.72901L8.87301 5.32234L6.99967 0.902344L5.12634 5.32234L0.333008 5.72901L3.97301 8.88234L2.87967 13.569L6.99967 11.0823L11.1197 13.569L10.0263 8.88234L13.6663 5.72901ZM8.72634 9.18234L9.09967 10.789L7.68634 9.93568L6.99967 9.52234L6.31301 9.93568L4.89967 10.789L5.27301 9.18234L5.45301 8.39568L4.84634 7.86901L3.59301 6.78234L5.23967 6.64234L6.03967 6.57568L6.35301 5.83568L6.99967 4.32234L7.64634 5.84901L7.95967 6.58901L8.75968 6.65568L10.4063 6.79568L9.15301 7.88234L8.54634 8.40901L8.72634 9.18234Z"
//         fill="#FFC107"
//       />
//     </svg>
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 16 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M13.6663 5.72901L8.87301 5.32234L6.99967 0.902344L5.12634 5.32234L0.333008 5.72901L3.97301 8.88234L2.87967 13.569L6.99967 11.0823L11.1197 13.569L10.0263 8.88234L13.6663 5.72901ZM8.72634 9.18234L9.09967 10.789L7.68634 9.93568L6.99967 9.52234L6.31301 9.93568L4.89967 10.789L5.27301 9.18234L5.45301 8.39568L4.84634 7.86901L3.59301 6.78234L5.23967 6.64234L6.03967 6.57568L6.35301 5.83568L6.99967 4.32234L7.64634 5.84901L7.95967 6.58901L8.75968 6.65568L10.4063 6.79568L9.15301 7.88234L8.54634 8.40901L8.72634 9.18234Z"
//         fill="#FFC107"
//       />
//     </svg>
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 16 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M13.6663 5.72901L8.87301 5.32234L6.99967 0.902344L5.12634 5.32234L0.333008 5.72901L3.97301 8.88234L2.87967 13.569L6.99967 11.0823L11.1197 13.569L10.0263 8.88234L13.6663 5.72901ZM8.72634 9.18234L9.09967 10.789L7.68634 9.93568L6.99967 9.52234L6.31301 9.93568L4.89967 10.789L5.27301 9.18234L5.45301 8.39568L4.84634 7.86901L3.59301 6.78234L5.23967 6.64234L6.03967 6.57568L6.35301 5.83568L6.99967 4.32234L7.64634 5.84901L7.95967 6.58901L8.75968 6.65568L10.4063 6.79568L9.15301 7.88234L8.54634 8.40901L8.72634 9.18234Z"
//         fill="#FFC107"
//       />
//     </svg>
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 16 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M13.6663 5.72901L8.87301 5.32234L6.99967 0.902344L5.12634 5.32234L0.333008 5.72901L3.97301 8.88234L2.87967 13.569L6.99967 11.0823L11.1197 13.569L10.0263 8.88234L13.6663 5.72901ZM8.72634 9.18234L9.09967 10.789L7.68634 9.93568L6.99967 9.52234L6.31301 9.93568L4.89967 10.789L5.27301 9.18234L5.45301 8.39568L4.84634 7.86901L3.59301 6.78234L5.23967 6.64234L6.03967 6.57568L6.35301 5.83568L6.99967 4.32234L7.64634 5.84901L7.95967 6.58901L8.75968 6.65568L10.4063 6.79568L9.15301 7.88234L8.54634 8.40901L8.72634 9.18234Z"
//         fill="#FFC107"
//       />
//     </svg>
//   </div>
//   <p className="text-[#444444] text-[14px] ml-2">(1279 Rating) </p>
// </div>

{
  /* Review  */
}
{
  /* <div className="">
          <p className="md:text-[20px] text-[14px]  text-[#444444]   mb-3">
            Reviews
          </p>

          <div className="my-3">
            <div className="flex justify-between">
              <div className="flex">
                <img
                  src="../listing/review-1.png"
                  alt=""
                  className="h-[80px] w-[80px]"
                />
                <div className="mx-3 flex items-center">
                  <div className="">
                    <p className="text-[14px]  text-[#444444]">Abdul Ahmed</p>
                    <p className="text-[12px]  text-[#787878]">20-02-2023</p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between w-[100px]">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6668 5.15992L8.8735 4.75325L7.00016 0.333252L5.12683 4.75325L0.333496 5.15992L3.9735 8.31325L2.88016 12.9999L7.00016 10.5133L11.1202 12.9999L10.0268 8.31325L13.6668 5.15992ZM8.72683 8.61325L9.10016 10.2199L7.68683 9.36659L7.00016 8.95325L6.3135 9.36659L4.90016 10.2199L5.2735 8.61325L5.4535 7.82659L4.84683 7.29992L3.5935 6.21325L5.24016 6.07325L6.04016 6.00659L6.3535 5.26659L7.00016 3.75325L7.64683 5.27992L7.96016 6.01992L8.76016 6.08659L10.4068 6.22659L9.1535 7.31325L8.54683 7.83992L8.72683 8.61325Z"
                    fill="#FFC107"
                  />
                </svg>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6668 5.15992L8.8735 4.75325L7.00016 0.333252L5.12683 4.75325L0.333496 5.15992L3.9735 8.31325L2.88016 12.9999L7.00016 10.5133L11.1202 12.9999L10.0268 8.31325L13.6668 5.15992ZM8.72683 8.61325L9.10016 10.2199L7.68683 9.36659L7.00016 8.95325L6.3135 9.36659L4.90016 10.2199L5.2735 8.61325L5.4535 7.82659L4.84683 7.29992L3.5935 6.21325L5.24016 6.07325L6.04016 6.00659L6.3535 5.26659L7.00016 3.75325L7.64683 5.27992L7.96016 6.01992L8.76016 6.08659L10.4068 6.22659L9.1535 7.31325L8.54683 7.83992L8.72683 8.61325Z"
                    fill="#FFC107"
                  />
                </svg>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6668 5.15992L8.8735 4.75325L7.00016 0.333252L5.12683 4.75325L0.333496 5.15992L3.9735 8.31325L2.88016 12.9999L7.00016 10.5133L11.1202 12.9999L10.0268 8.31325L13.6668 5.15992ZM8.72683 8.61325L9.10016 10.2199L7.68683 9.36659L7.00016 8.95325L6.3135 9.36659L4.90016 10.2199L5.2735 8.61325L5.4535 7.82659L4.84683 7.29992L3.5935 6.21325L5.24016 6.07325L6.04016 6.00659L6.3535 5.26659L7.00016 3.75325L7.64683 5.27992L7.96016 6.01992L8.76016 6.08659L10.4068 6.22659L9.1535 7.31325L8.54683 7.83992L8.72683 8.61325Z"
                    fill="#FFC107"
                  />
                </svg>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6668 5.15992L8.8735 4.75325L7.00016 0.333252L5.12683 4.75325L0.333496 5.15992L3.9735 8.31325L2.88016 12.9999L7.00016 10.5133L11.1202 12.9999L10.0268 8.31325L13.6668 5.15992ZM8.72683 8.61325L9.10016 10.2199L7.68683 9.36659L7.00016 8.95325L6.3135 9.36659L4.90016 10.2199L5.2735 8.61325L5.4535 7.82659L4.84683 7.29992L3.5935 6.21325L5.24016 6.07325L6.04016 6.00659L6.3535 5.26659L7.00016 3.75325L7.64683 5.27992L7.96016 6.01992L8.76016 6.08659L10.4068 6.22659L9.1535 7.31325L8.54683 7.83992L8.72683 8.61325Z"
                    fill="#FFC107"
                  />
                </svg>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6668 5.15992L8.8735 4.75325L7.00016 0.333252L5.12683 4.75325L0.333496 5.15992L3.9735 8.31325L2.88016 12.9999L7.00016 10.5133L11.1202 12.9999L10.0268 8.31325L13.6668 5.15992ZM8.72683 8.61325L9.10016 10.2199L7.68683 9.36659L7.00016 8.95325L6.3135 9.36659L4.90016 10.2199L5.2735 8.61325L5.4535 7.82659L4.84683 7.29992L3.5935 6.21325L5.24016 6.07325L6.04016 6.00659L6.3535 5.26659L7.00016 3.75325L7.64683 5.27992L7.96016 6.01992L8.76016 6.08659L10.4068 6.22659L9.1535 7.31325L8.54683 7.83992L8.72683 8.61325Z"
                    fill="#FFC107"
                  />
                </svg>
              </div>
            </div>
            <div className="my-2">
              <p className="text-[#757575] text-[14px] text-justify leading-[22px]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged.
              </p>
            </div>
          </div>

          <div className="my-3">
            <div className="flex justify-between">
              <div className="flex">
                <img
                  src="../listing/review-1.png"
                  alt=""
                  className="h-[80px] w-[80px]"
                />
                <div className="mx-3 flex items-center">
                  <div className="">
                    <p className="text-[14px] font-[500] text-[#444444]">
                      Abdul Ahmed
                    </p>
                    <p className="text-[12px] font-[400] text-[#787878]">
                      20-02-2023
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between w-[100px]">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6668 5.15992L8.8735 4.75325L7.00016 0.333252L5.12683 4.75325L0.333496 5.15992L3.9735 8.31325L2.88016 12.9999L7.00016 10.5133L11.1202 12.9999L10.0268 8.31325L13.6668 5.15992ZM8.72683 8.61325L9.10016 10.2199L7.68683 9.36659L7.00016 8.95325L6.3135 9.36659L4.90016 10.2199L5.2735 8.61325L5.4535 7.82659L4.84683 7.29992L3.5935 6.21325L5.24016 6.07325L6.04016 6.00659L6.3535 5.26659L7.00016 3.75325L7.64683 5.27992L7.96016 6.01992L8.76016 6.08659L10.4068 6.22659L9.1535 7.31325L8.54683 7.83992L8.72683 8.61325Z"
                    fill="#FFC107"
                  />
                </svg>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6668 5.15992L8.8735 4.75325L7.00016 0.333252L5.12683 4.75325L0.333496 5.15992L3.9735 8.31325L2.88016 12.9999L7.00016 10.5133L11.1202 12.9999L10.0268 8.31325L13.6668 5.15992ZM8.72683 8.61325L9.10016 10.2199L7.68683 9.36659L7.00016 8.95325L6.3135 9.36659L4.90016 10.2199L5.2735 8.61325L5.4535 7.82659L4.84683 7.29992L3.5935 6.21325L5.24016 6.07325L6.04016 6.00659L6.3535 5.26659L7.00016 3.75325L7.64683 5.27992L7.96016 6.01992L8.76016 6.08659L10.4068 6.22659L9.1535 7.31325L8.54683 7.83992L8.72683 8.61325Z"
                    fill="#FFC107"
                  />
                </svg>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6668 5.15992L8.8735 4.75325L7.00016 0.333252L5.12683 4.75325L0.333496 5.15992L3.9735 8.31325L2.88016 12.9999L7.00016 10.5133L11.1202 12.9999L10.0268 8.31325L13.6668 5.15992ZM8.72683 8.61325L9.10016 10.2199L7.68683 9.36659L7.00016 8.95325L6.3135 9.36659L4.90016 10.2199L5.2735 8.61325L5.4535 7.82659L4.84683 7.29992L3.5935 6.21325L5.24016 6.07325L6.04016 6.00659L6.3535 5.26659L7.00016 3.75325L7.64683 5.27992L7.96016 6.01992L8.76016 6.08659L10.4068 6.22659L9.1535 7.31325L8.54683 7.83992L8.72683 8.61325Z"
                    fill="#FFC107"
                  />
                </svg>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6668 5.15992L8.8735 4.75325L7.00016 0.333252L5.12683 4.75325L0.333496 5.15992L3.9735 8.31325L2.88016 12.9999L7.00016 10.5133L11.1202 12.9999L10.0268 8.31325L13.6668 5.15992ZM8.72683 8.61325L9.10016 10.2199L7.68683 9.36659L7.00016 8.95325L6.3135 9.36659L4.90016 10.2199L5.2735 8.61325L5.4535 7.82659L4.84683 7.29992L3.5935 6.21325L5.24016 6.07325L6.04016 6.00659L6.3535 5.26659L7.00016 3.75325L7.64683 5.27992L7.96016 6.01992L8.76016 6.08659L10.4068 6.22659L9.1535 7.31325L8.54683 7.83992L8.72683 8.61325Z"
                    fill="#FFC107"
                  />
                </svg>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6668 5.15992L8.8735 4.75325L7.00016 0.333252L5.12683 4.75325L0.333496 5.15992L3.9735 8.31325L2.88016 12.9999L7.00016 10.5133L11.1202 12.9999L10.0268 8.31325L13.6668 5.15992ZM8.72683 8.61325L9.10016 10.2199L7.68683 9.36659L7.00016 8.95325L6.3135 9.36659L4.90016 10.2199L5.2735 8.61325L5.4535 7.82659L4.84683 7.29992L3.5935 6.21325L5.24016 6.07325L6.04016 6.00659L6.3535 5.26659L7.00016 3.75325L7.64683 5.27992L7.96016 6.01992L8.76016 6.08659L10.4068 6.22659L9.1535 7.31325L8.54683 7.83992L8.72683 8.61325Z"
                    fill="#FFC107"
                  />
                </svg>
              </div>
            </div>
            <div className="my-2">
              <p className="text-[#757575] text-[14px] text-justify ">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged.
              </p>
            </div>
          </div>
        </div> */
}

{
  /* login to post button  */
}

{
  /* <div className="flex justify-center my-2 mb-10">
          {!loggedIn ? (
            <button
              // onClick={FirstLogin}
              className="w-[200px] h-[40px] rounded-[4px] text-[16px] text-[#008081] border border-[#008081]"
            >
              Login to Post Review
            </button>
          ) : (
            <button
              // onClick={FirstLogin}
              className="w-[200px] h-[40px] rounded-[4px] text-[16px] text-[#008081] border border-[#008081]"
            >
              Post Review
            </button>
          )}
        </div> */
}
