// ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import LoginPopup from "../Loginpopup";

const ProtectedRoute = ({ element, isAuthenticated }) => {
  return isAuthenticated ? element : <LoginPopup />;
};

export default ProtectedRoute;
