import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Herosection from './Component/herosection';
import Footer from './Component/footer';

import Complete_Profile from './Component/complete profile';
import Categories from './Component/all_categories';

import AddContact from './Component/add_contact';

import Time from './Component/time';
import Business_Category from './Component/business_category';
import Add_Photo from './Component/addphoto';
import MyBusiness from './Component/mybusiness';
import Login from './Component/login';
import Register from './Component/register';

import Listing_View from './Component/listing_view';
import User_Profile from './Component/user_profile';
import My_Profile from './Component/My profile';

import All_Categories from './Component/categories';

import ResetPassword from './Component/resetpassword';

import ForgotPassword from './Component/forgotpassword';
import Business_ListingPage from './Component/business listing page';
import Add_cost from './Component/add_cost';
import RecentAddListing from './Component/recentaddlisting';
import MostBookedListing from './Component/mostbookedlisting';
import About from './Component/company/about';
import Careers from './Component/company/careers';
import Contact from './Component/company/contact';
import TermsAndCondition from './Component/footer/termsandcondition';
import PrivacyPolicy from './Component/footer/privacypolicy';
import CancellationPolicy from './Component/footer/cancellationpolicy';
import LoginPopup from './Component/Loginpopup/index';
import ProtectedRoute from './Component/protectedroute';
import { useEffect, useState } from 'react';
import {jwtDecode }from 'jwt-decode'; 
import Paymentcard from './Component/paymentcard';
import Paymentsucess_failed from './Component/paymentsuccess_failure';

function App() {

  const [loggedIn, setLoggedIn] = useState(false);

  const [userDetails, setUserDetails] = useState("");


  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token != null) {
      const userInfo = jwtDecode(token);
      setUserDetails(userInfo);
      setLoggedIn(true);

    }
  }, []);

  return (
    
    <>

      <BrowserRouter>

        <Routes>
          <Route path='/' element={<Herosection />} />
          <Route path='/complete-profile' element={<Complete_Profile />} />
          <Route path='/category' element={<Categories />} />
          <Route path='/addcontact' element={<AddContact />} />
          <Route path='/time' element={<Time />} />
          <Route path='/business-category' element={<Business_Category />} />
          <Route path='/add-photo' element={<Add_Photo />} />
          <Route path='/my-business' element={<MyBusiness />} />
          <Route path='/login' element={<Login />} />
          <Route path='/listing' element={<Listing_View />} />
          <Route path='/categories' element={<All_Categories />} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/resetpassword' element={<ResetPassword />} />
          <Route path='/businesslisting' element={<Business_ListingPage />} />
          <Route path='/add_cost' element={<Add_cost />} />
          <Route path='/addlisting' element={<RecentAddListing />} />
          <Route path='/mostbookedlisting' element={<MostBookedListing />} />
          <Route path='/about' element={<About />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/Terms and Condition' element={<TermsAndCondition />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/cancellationpolicy' element={<CancellationPolicy />} />
          <Route path='/log-In' element={<LoginPopup />} />
          <Route path='/paymentcard' element={<Paymentcard />} />
          <Route path='/confimationpage' element={<Paymentsucess_failed />} />



          {/* Protected Routes */}
          <Route
            path='/user_profile'
            element={<ProtectedRoute element={<User_Profile />} isAuthenticated={loggedIn} />}
          />
          <Route
            path='/my_profile'
            element={<ProtectedRoute element={<My_Profile />} isAuthenticated={loggedIn} />}
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
