import React, { useEffect, useState } from "react";
import Navbarr from "../navbar";
import { Link } from "react-router-dom";
import axios from "axios";

export default function ForgotPassword() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setSuccessMessage("");

      const response = await axios.post(`${baseUrl}/users/resetpassword`, {
        email,
      });

      if (response.data.isSuccess) {
        localStorage.setItem("resetToken", response.data.token);
        console.log("dfff", response.data.token);

        setSuccessMessage(response.data.message);
        console.log("dfff", response.data.token);
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      console.error("Error:", err);
      setError("An error occurred. Please try again later.");
    }
  };
  return (
    <>
      <div className="]">
        <div className="lg:w-[100%] px-3 lg:px-0">
          <p className="md:text-[48px] text-[34px] font-[700] text-[#444444] md:py-5 vol">
            Forget <br />
            <span className="text-[#008081] vol">Password</span>
          </p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Enter your Registered Email address"
              name="email"
              value={email}
              onChange={handleChange}
              className=" mb-5 text-[#787878]  text-[12px] pl-5 w-full h-[48px] border-[#DDDDDD] border rounded-[8px]"
            />
            <div className="flex justify-center">
              <button
                type="submit"
                className="w-[173px] roboto h-[41px] bg-[#008081] text-[#ffffff] rounded-[4px] ml-3"
              >
                Reset Password
              </button>
            </div>
          </form>
          {successMessage && <p className="text-green-500">{successMessage}</p>}
          {error && <p className="">{error}</p>}
        </div>
      </div>
    </>
  );
}
