import React, { useState } from "react";
import {
  useElements,
  useStripe,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentForm = ({ clientSecret }) => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate for redirection

  const { totalAmount, currency, taxAmount, subTotal, orderSummary } =
    location.state || {};
  console.log(orderSummary, "ordersummary");

  const stripe = useStripe();
  const elements = useElements();

  // State for user inputs
  const [name, setName] = useState(""); // Dynamic name input
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (!stripe || !elements) {
      return; // Stripe.js has not yet loaded
    }

    try {
      if (!clientSecret) {
        throw new Error("Failed to fetch client secret.");
      }
      const bookingDetails = {
        totalAmount,
        currency,
        taxAmount,
        subTotal,
        orderSummary,
        customerName: name,
      };
      localStorage.setItem(
        "bookingDetailsnexlum",
        JSON.stringify(bookingDetails)
      );
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `http://localhost:3000/confimationpage`,
          payment_method_data: {
            billing_details: {
              name: name,
            },
          },
        },
      });

      if (error) {
        setErrorMessage(error.message);
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        setPaymentSuccessful(true);
      }
    } catch (error) {
      setErrorMessage(error.message || "An unexpected error occurred.");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="block md:flex gap-5 mb-2">
        <label className="text-[16px]" htmlFor="name">
          Name On Card
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          placeholder="Enter your name"
          className="border-[0.5px] border-[#222222] rounded-md pl-2"
        />
      </div>

      <div className="md:w-[500px]">
        <PaymentElement />
      </div>

      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <button
        className="py-2 bg-[#008081] mt-0 md:mt-2 hover:bg-[#0c2e2e] px-2 rounded-md text-[#ffffff]"
        disabled={!stripe || isProcessing}
        type="submit"
      >
        {isProcessing ? "Processing..." : "Pay Now"}
      </button>
      {paymentSuccessful && <p>Payment was successful!</p>}
    </form>
  );
};

export default PaymentForm;
