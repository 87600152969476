import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/solid";

function Success() {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(5);

  useEffect(() => {
    // Timer to redirect after 5 seconds
    const timer = setTimeout(() => {
      navigate("/");
    }, 5000);

    // Countdown interval
    const interval = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          return prev;
        }
        return prev - 1;
      });
    }, 1000);

    // Cleanup timers on component unmount
    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-50 text-green-700 p-6 ">
      <CheckCircleIcon className="w-16 h-16 text-green-500 mt-20 lg:mt-0" />
      <h1 className="mt-4 text-2xl font-semibold">Payment Successful!</h1>
      <p className="mt-2 text-center">
        Thank you for your payment. Your transaction has been completed, and a
        receipt has been sent to your email.
      </p>
      <p className="mt-4 p-2 rounded-lg ">
        You will be redirected to the dashboard in{" "}
        <span className="bg-green-600 rounded-lg text-[#ffffff] p-2">
          {timeLeft} seconds
        </span>
      </p>
      <button
        className="mt-8 px-6 py-3 bg-green-600 text-white rounded-md shadow hover:bg-green-700"
        onClick={() => navigate("/")}
      >
        Go to Dashboard
      </button>
    </div>
  );
}

export default Success;
